import * as React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import { StaticImage } from "gatsby-plugin-image"

import "../assets/css/main.css"

const IndexPage = () => {
  return (
    <main className="flex flex-col h-screen bg-black-1">
      <Navbar />
      <div className="grid place-items-center h-screen">
        <div className="mx-8">
          <p className="text-white text-xl md:text-3xl font-main text-center">
            Stack your skills with GetterStack - your ultimate coding companion!
          </p>
          {/* <div className="flex justify-center mt-16">
            <a href="https://play.google.com/store/apps/details?id=com.devloaf">
              <StaticImage
                src="../assets/images/google_play_badge.png"
                alt="google play application link"
                placeholder="blurred"
              />
            </a>
          </div> */}
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default IndexPage
